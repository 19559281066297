import {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption, trainingOption1Bundle, trainingOption2Bundle} from './Training3Data'
import PurchaseList from '../../components/PurchaseList';
import PurchaseListNavTraining from '../../components/PurchaseListNavTraining';
import Location from '../../components/Location.jsx'
import LogisiticsDetails from '../../components/LogisiticsDetails';
import 'animate.css';

export default function Training3() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Basketball Training </h4>
        <h4 className='disclaimer'>Training Days Are T/W/Th</h4>
        <div className='camp__container'>
          <div className="purchase">
          {/* <PurchaseListNavTraining url="Training3" /> */}
          <PurchaseList 
            type="ThreeDayBundle"
            header="Beginner"
            subhead=" Shooting Finishing, Decision Making Classes Available!"
            data={trainingOption1Bundle}
          />
          <PurchaseList 
            type="TwoDayBundle"
            header="Intermediate"
            subhead="Shooting Finishing, Decision Making Classes Available!"
            data={trainingOption2Bundle}
          />
          {/* <PurchaseList
            type="Tuesday"
            header="Tuesday Training from 6:00-7:30pm"
            subhead="$100/ 5 Classes"
            data={tuesdayTrainingOption}
          />
          <PurchaseList
            type="Wednesday"
            header="Wednesday Training from 6:00-7:30pm"
            subhead="$100/ 5 Classes"
            data={wednesdayTrainingOption}
          />
            <PurchaseList
            type="Thursday"
            header="Thursday Training from 6:00-7:30pm"
            subhead="$100/ 5 Classes"
            data={thursdayTrainingOption}
          /> */}

        </div>

        <div className='logistics'>
        <Location 
            link="https://www.d114.org/elementary-school"
            name="Lotus School"
            street="29067 W Grass Lake Rd"
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={trainingInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={trainingDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}