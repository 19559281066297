const trainingOption1Bundle = [
  {
    link: "https://sixzerosixacademy.playbookapi.com/programs/register/",
    name: "Summer Camps",
    date: "9AM to 3PM M-F",
  },
]

const trainingInfo = [
  "Registration closes one day prior to start date",
  "Bring a basketball and a water bottle.",
  "Payment Plans Available",
  "Sibling Discounts Available"
]

const trainingDrills = [
  "Basketball",
  "Football",
  "Soccer",
  "Kick Ball",
  "Fun Outside",
  "Fun in the Park",
  "Movies",
  "Snacks",
  "Games",
  "Scrimmages",
  "1 on 1, 2 v 2",
  "Make Friends",
  "and more!"
]



export {trainingDrills, trainingInfo, trainingOption1Bundle}