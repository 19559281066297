const trainingOption1Bundle = [
  {
    link: "https://sixzerosixacademy.playbookapi.com/programs/register/",
    name: "Full Day Clinics",
    date: "9:00AM to 3:00PM",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://sixzerosixacademy.playbookapi.com/programs/register/",
    name: "Full Day Clinics",
    date: "9:00AM to 3:00PM",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "January 21st",
    date: "February 18th",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "January 22nd",
    date: "February 19th",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "January 23rd",
    date: "February 20th",
  },
]


const trainingInfo = [
  "Registration closes one day prior to start date",
  "Bring a basketball and a water bottle.",
]

const trainingDrills = [
  "● 9:00 - 9:15 AM: Arrival and Check-in (Light stretching/warm-up as kids arrive)",
  "● 9:15 - 9:30 AM: Welcome and Introductions (Camp rules, expectations, staff introductions)",
  "● 9:30 - 10:30 AM: Fundamental Skills Development (Dribbling, passing, shooting form) -Stations may be used to allow for focused work on specific skills.",
  "● 10:30 - 11:30 AM: Advanced Skills Training (Pivoting, footwork, offensive/defensive drills)",
  "● 11:30 AM - 12:30 PM: Small Group Competition/Games (3-on-3, King of the Court, etc.)",
  "● 12:30 - 1:30 PM: Lunch Break (Opportunity for campers to socialize and relax)",
  "● 1:30 - 2:30 PM: Team Strategy and Play Development (Offensive and defensive systems, game situations)",
  "● 2:30 - 3:30 PM: Scrimmage or Full-Court Game Play (Focus on applying learned skills and strategies)",
  "● 3:30 - 4:00 PM: Cool-down, Stretching, and Wrap-up (Review of the day, announcements, Q&A)"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}