import React from 'react'
import MeyerLogo from '../static/FooterPartner/MeyerLogo-80h.png'
import GrassLakeLogo from '../static/FooterPartner/GrassLakeLogo-80h.png'
import LotusLogo from '../static/FooterPartner/lotus-logo.png'
import StantonLogo from '../static/FooterPartner/stanton-logo.png'
import PlayTymeLogo from '../static/playtyme.webp'

export default function PartnerIcons() {
   return (
    <p className="d-flex flex-row justify-content-around">
      {/* <a href="https://www.gls36.org/" target="_blank" rel="noreferrer">
        <img
          className="img-fluid mb-4 pl-0 partner"
          src={GrassLakeLogo}
          alt='Grass Lake School'
        />
      </a> */}
      <a href="https://www.d114.org/middle-school" target="_blank" rel="noreferrer">
        <img
          className="img-fluid mb-4 pl-0 partner"
          src={StantonLogo}
          alt='Stanton School'
        />
      </a>
      <a href="https://www.sd735.org/meyer" target="_blank" rel="noreferrer">
        <img
          className="img-fluid mb-4 pl-0 partner"
          src={MeyerLogo}
          alt='Elizabeth Meyer School'
        />
      </a>
      {/* <a href="https://playtymegym.com/" target="_blank" rel="noreferrer">
        <img
          className="img-fluid mb-4 pl-0 partner"
          src={PlayTymeLogo}
          alt='Play Tyme Logo'
        />
      </a> */}
      <a href="https://www.d114.org/elementary-school" target="_blank" rel="noreferrer">
        <img
          className="img-fluid mb-4 pl-0 partner"
          src={LotusLogo}
          alt='Lotus Elementary School'
        />
      </a>
    </p>
   )
}
